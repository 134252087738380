import { createStore, select, withProps } from '@ngneat/elf';
import { Injectable } from '@angular/core';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface GlobalProps {
  bgColor: 'white' | 'black';
}

const store = createStore({ name: 'global' }, withProps<GlobalProps>({ bgColor: 'white' }));

@Injectable({ providedIn: 'root' })
export class GlobalRepository {
  public store = store;

  bgColor$ = this.store.pipe(select(state => state.bgColor));
}

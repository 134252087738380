import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { enableElfProdMode } from '@ngneat/elf';
import { devTools } from '@ngneat/elf-devtools';
import { loadDevMessages, loadErrorMessages } from '@apollo/client/dev';

if (environment.production) {
  enableElfProdMode();
} else {
  devTools();
  loadDevMessages();
  loadErrorMessages();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));

import { Component } from '@angular/core';
import { GlobalRepository } from './state/global/global.repository';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'heyniceplants-shopify';

  bgColor$ = this.globalRepository.bgColor$;

  constructor(private readonly globalRepository: GlobalRepository) {}
}

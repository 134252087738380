import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule),
  },
  {
    path: 'choices',
    loadChildren: () => import('./modules/choices/choices.module').then(m => m.ChoicesModule),
  },
  {
    path: 'reviews',
    loadChildren: () => import('./modules/reviews/reviews.module').then(m => m.ReviewsModule),
  },
  {
    path: 'faq',
    loadChildren: () => import('./modules/faq/faq.module').then(m => m.FaqModule),
  },
  {
    path: 'carecards',
    loadChildren: () => import('./modules/carecards/carecards.module').then(m => m.CarecardsModule),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking',
      preloadingStrategy: PreloadAllModules,
      scrollPositionRestoration: 'top',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}

import { APOLLO_NAMED_OPTIONS, ApolloModule, NamedOptions } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { NgModule } from '@angular/core';
import { ApolloLink, InMemoryCache } from '@apollo/client/core';
import { environment } from '../environments/environment';
import { setContext } from '@apollo/client/link/context';

@NgModule({
  exports: [ApolloModule],
  providers: [
    {
      provide: APOLLO_NAMED_OPTIONS,
      useFactory(httpLink: HttpLink): NamedOptions {
        const storeFrontAuth = setContext(() => ({
          headers: {
            'X-Shopify-Storefront-Access-Token': environment.storefrontShopifyApiAccessToken,
          },
        }));

        return {
          storefront: {
            cache: new InMemoryCache(),
            link: ApolloLink.from([storeFrontAuth, httpLink.create({ uri: environment.storefrontShopifyApi })]),
          },
        };
      },
      deps: [HttpLink],
    },
  ],
})
export class GraphQLModule {}
